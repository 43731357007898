import React from "react"
import SEO from "components/seo"
import Heading from "components/Heading"
import HeroImageFull from "components/HeroImageFull"
import { Photo } from "components/Img"
import VideoWrapper from "components/VideoWrapper"

const ViewCommunity = ({ pageData }) => {
  const {
    contentSupportedBy: {
      title: titleSupportedBy,
      slideshow: slideshowSupportedBy,
    },
    contentFutureFashion: {
      title: titleFutureFashion,
      slideshow: slideshowFutureFashion,
    },
    contentTeaSession: {
      title: titleTeaSession,
      subtitle: subtitleTeaSession,
      youtubeId: youtubeTeaSession,
    },
    heading,
    page,
  } = pageData

  return (
    <React.Fragment>
      <SEO title={page} />
      <div className="max-container real-hero wrapper--community--hero">
        <HeroImageFull
          heroTextClassname="hero-text-community mobile--is-center"
          imageName="communityHeroImage"
          imageProps={{
            imgStyle: {
              objectPosition: "center top",
            },
          }}
        >
          <Heading className="is-heading">{heading}</Heading>
        </HeroImageFull>
      </div>

      <div className="max-container wrapper--community--block-supported-by">
        <div className="container max-1048">
          <Heading>{titleSupportedBy}</Heading>
          <div className="columns is-multiline">
            {slideshowSupportedBy.map(({ image, title }, index) => {
              return (
                <div
                  key={`supported-slide-image-${index}`}
                  className="column is-one-third each-image"
                >
                  <div className="swiper-caption-item">
                    <Photo
                      className="swiper-caption--image"
                      data={image}
                      alt="Supported By"
                    />
                    <div className="swiper-caption--caption">{title}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <div className="max-container wrapper--community--block-tea-session">
        <Heading className="title">{titleTeaSession}</Heading>
        <h3 className="subtitle">{subtitleTeaSession}</h3>
        <VideoWrapper videoId={youtubeTeaSession} />
      </div>

      <div className="max-container wrapper--community--block-future-fashion">
        <div className="container max-1048">
          <Heading>{titleFutureFashion}</Heading>
          <div className="columns is-multiline">
            {slideshowFutureFashion.map(({ image, title }, index) => {
              return (
                <div
                  key={`future-slide-image-${index}`}
                  className="column is-one-third each-image"
                >
                  <div className="swiper-caption-item">
                    <Photo
                      className="swiper-caption--image"
                      data={image}
                      alt="Future of Fashion"
                    />
                    <div className="swiper-caption--caption">{title}</div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ViewCommunity
