import { useStaticQuery, graphql } from "gatsby";

const usePageData = () => {
  const data = useStaticQuery(
    graphql`
    {
      data: allDataYaml(filter: { codePage: { eq: "community-page" } }) {
        edges {
          node {
            ...fragmentCommunityPage
          }
        }
      }
    }
  `);

  return data.data.edges[0].node
}

export default usePageData;
